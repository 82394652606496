<!-- Mount all icon-like elements without attribute iconPositionEnd -->
<ng-content
  select="
  fa-icon:not([iconPositionEnd]),
  fa-duotone-icon:not([iconPositionEnd]),
  fa-layers:not([iconPositionEnd]),
  fa-stack:not([iconPositionEnd]),
  [fmntsButtonIcon]:not([iconPositionEnd])"
></ng-content>

<span class="fmnts-button__label"><ng-content></ng-content></span>

<!-- Mount all icon-like elements with attribute iconPositionEnd -->
<ng-content
  select="
  fa-icon[iconPositionEnd],
  fa-duotone-icon[iconPositionEnd
  fa-layers[iconPositionEnd],
  fa-stack[iconPositionEnd],
  [fmntsButtonIcon][iconPositionEnd]"
></ng-content>
