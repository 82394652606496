import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { AlignBlock, AlignInline } from '@fmnts/components';

/**
 * Displays a group of buttons.
 */
@Component({
  selector: `fmnts-button-group, [fmnts-button-group]`,
  templateUrl: 'button-group.component.html',
  styleUrls: ['button-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonGroupComponent {
  @HostBinding('class.fmnts-button-group') protected readonly componentClass =
    'fmnts-button-group';

  /**
   * Justify aligns the content along horizontally.
   */
  @Input() justify: `${AlignInline}` = 'end';
  /**
   * Align aligns the content along the vertically.
   */
  @Input() align: `${AlignBlock}` = 'center';

  @HostBinding('class')
  get hostClasses(): string {
    return classnames([
      this.justify && `${this.componentClass}--justify-content-${this.justify}`,
      this.align && `${this.componentClass}--align-items-${this.align}`,
    ]);
  }
}
