import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { FmntsApiValidationError } from '@fmnts/api';
import { isNonEmptyString, isRecord, none, Option } from '@fmnts/core';
import { InlineTranslateable } from '@fmnts/i18n';

type ApiErrorMessage = InlineTranslateable;

/** Error code that is used to store server validation errors. */
const API_ERROR_PROPERTY = 'server_validation';

/** Property that is used as a name to store validation errors spanning multiple fields. */
const API_PROP_FOR_GENERAL_ERRORS = 'non_field_errors';

function isApiErrorMessage(s: unknown): s is ApiErrorMessage {
  return isNonEmptyString(s) || isRecord(s);
}

interface ServerValidationError {
  [API_ERROR_PROPERTY]: ApiErrorMessage[];
}

@Injectable({
  providedIn: 'root',
})
export class ApiValidationErrorControlHandler {
  /**
   * Property that is used to store errors from the API.
   */
  readonly ngControlErrorCode = API_ERROR_PROPERTY;

  /**
   * Helper that returns the api validation error messages on a control.
   * @param control Control
   *
   * @returns
   * API validation error messages
   */
  getFromControlErrors(control: AbstractControl): Option<ApiErrorMessage[]> {
    return this.getFromControlValidationErrors(control.errors);
  }

  /**
   * Helper that returns the api validation error messages.
   * @param ctrlErrors Validation errors on control.
   *
   * @returns
   * API validation error messages
   */
  getFromControlValidationErrors(
    ctrlErrors: ValidationErrors | null,
  ): Option<ApiErrorMessage[]> {
    const errors: unknown = ctrlErrors?.[this.ngControlErrorCode];

    if (!errors || !Array.isArray(errors) || errors.length === 0) {
      return none;
    }

    return errors.filter(isApiErrorMessage);
  }

  /**
   * @param apiError Fmnts API error
   * @returns
   * Errors for the form based on the given `apiError`
   * or `null` if there are none.
   */
  handleFormError(
    apiError: FmntsApiValidationError,
  ): Option<ServerValidationError> {
    return this.handleFieldError(apiError, API_PROP_FOR_GENERAL_ERRORS);
  }

  /**
   * @param apiError Fmnts API error
   * @param field Name of the field
   *
   * @returns
   * Errors for the given `field` based on the given `apiError`
   * or `null` if there are none.
   */
  handleFieldError(
    apiError: FmntsApiValidationError,
    field: string | number,
  ): Option<ServerValidationError> {
    const error = _extractServerValidationErrorMessages(apiError, field);

    return error.length > 0 ? { [this.ngControlErrorCode]: error } : none;
  }

  /**
   * Sets the API validation errors from the given `apiError` on the
   * given form `group`.
   *
   * @param apiError API validation errors
   * @param group Form group
   */
  setErrorsFromApiOnFormGroup(
    apiError: FmntsApiValidationError,
    group: FormGroup,
  ): void {
    for (const [field, ctrl] of Object.entries(group.controls)) {
      ctrl.setErrors(this.handleFieldError(apiError, field));
    }

    group.setErrors(this.handleFormError(apiError));
  }
}

function _extractServerValidationErrorMessages(
  apiError: FmntsApiValidationError,
  field: string | number,
): ApiErrorMessage[] {
  const non_field_errors = apiError.details[field];

  return Array.isArray(non_field_errors) ? non_field_errors : [];
}
