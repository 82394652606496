import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfigService } from '@fmnts/api';
import { GeneralHttpError } from '@fmnts/shared/errors/data-access';
import { Observable, catchError } from 'rxjs';

// TODO: move to app/shared/infra
@Injectable()
export class TransformHttpErrorInterceptor implements HttpInterceptor {
  constructor(private apiConfig: ApiConfigService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        // TODO: Reconsider if we really should change the emitted error
        // because the HTTP interceptor essentially hooks inbetween the
        // HttpClient's calls, which makes error handling in @fmnts/api
        // unpredictable.
        throw GeneralHttpError.fromHttpErrorResponse(
          err,
          this.apiConfig.isOurEndpoint(req.url) ? 'fmnts' : 'unknown',
        );
      }),
    );
  }
}
