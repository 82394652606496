/**
 * IDs of Formunauts payment methods that use Tamaro.
 */
export enum TamaroPaymentMethod {
  ApplePay = 'apple_pay_tamaro',
  CreditCard = 'cc_tamaro',
  DirectDebit = 'dd_tamaro',
  GooglePay = 'google_pay_tamaro',
  Twint = 'twint_tamaro',
  /** Wallet combines apple pay / google pay */
  Wallet = 'wallet_tamaro',
}

/**
 * IDs of Formunauts payment methods that use RaiseNow Epik.
 */
export enum EpikPaymentMethod {
  /** Using RaiseNows EPP platform */
  CreditCardViaEpp = 'cc_raisenow',
  CreditCardViaEpms = 'cc_new_paymentplatform',
  /** MPOS using the legacy mobile app */
  MposLegacy = 'cc_mpos',
  MposViaWebTerminal = 'cc_mpos_new',
}

/**
 * IDs of Formunauts payment methods.
 * Not all payment methods are available via enums here, so this maps
 * to only a string type.
 */
export type PaymentMethod = string;

/**
 * Checks whether the given `paymentMethod` uses tamaro widget.
 *
 * @param paymentMethod payment method
 *
 * @returns
 * `true` if the payment method uses tamaro.
 */
export function usesTamaro(
  paymentMethod: string,
): paymentMethod is TamaroPaymentMethod {
  switch (paymentMethod) {
    case TamaroPaymentMethod.ApplePay:
    case TamaroPaymentMethod.CreditCard:
    case TamaroPaymentMethod.DirectDebit:
    case TamaroPaymentMethod.GooglePay:
    case TamaroPaymentMethod.Twint:
    case TamaroPaymentMethod.Wallet:
      return true;
    default:
      return false;
  }
}

/**
 * Checks whether the given `paymentMethod` uses @raisenow/epik.
 *
 * @param paymentMethod payment method
 *
 * @returns
 * `true` if the payment method uses @raisenow/epik.
 */
export function usesEpik(
  paymentMethod: PaymentMethod,
): paymentMethod is EpikPaymentMethod {
  switch (paymentMethod) {
    case EpikPaymentMethod.CreditCardViaEpms:
    case EpikPaymentMethod.CreditCardViaEpp:
    case EpikPaymentMethod.MposLegacy:
    case EpikPaymentMethod.MposViaWebTerminal:
      return true;
    default:
      return false;
  }
}

/**
 * @param paymentMethod Formunauts payment method
 * @returns
 * The payment methods as required by the tamaro widget config.
 */
export function getConnectedTamaroPaymentMethod(
  paymentMethod: TamaroPaymentMethod,
): string[] {
  switch (paymentMethod) {
    case TamaroPaymentMethod.ApplePay:
      return ['apple_pay'];
    case TamaroPaymentMethod.CreditCard:
      return ['card'];
    case TamaroPaymentMethod.DirectDebit:
      return ['sepa_dd'];
    case TamaroPaymentMethod.GooglePay:
      return ['google_pay'];
    case TamaroPaymentMethod.Twint:
      return ['twint'];
    case TamaroPaymentMethod.Wallet:
      return ['apple_pay', 'google_pay'];
    default:
      return [];
  }
}
