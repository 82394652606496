import { Injectable } from '@angular/core';
import { AuthInfo } from '@fmnts/api/auth';
import { selectAuth } from '@fmnts/shared/auth/data-access';
import { createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import { tap } from 'rxjs';

@Injectable()
export class SentryEffects {
  setSentryUser$ = createEffect(
    () => {
      return this.store.select(selectAuth).pipe(
        tap((auth) => {
          Sentry.setUser(auth ? mapAuthInfoToSentryUser(auth) : null);
        }),
      );
    },
    { dispatch: false },
  );

  constructor(private store: Store) {}
}

/**
 * @param authInfo Auth info
 * @returns
 * Object to identify user on sentry
 */
function mapAuthInfoToSentryUser(authInfo: AuthInfo) {
  const user: Sentry.User = {
    username: authInfo.username ?? undefined,
    // Custom properties
    customerId: authInfo.customerId,
    isActive: authInfo.isActive,
  };

  return user;
}
