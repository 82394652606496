import { ErrorHandler, Injectable, inject } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { logger } from '@fmnts/common/log';
import {
  GeneralHttpError,
  HttpClientOrNetworkError,
} from '@fmnts/shared/errors/data-access';
import { logMessageFromSentryBreadcrumb } from '@fmnts/shared/sentry/infra';
import * as Sentry from '@sentry/angular-ivy';
import { TransactionContext } from '@sentry/types';
import { first } from 'rxjs';
import { Settings } from '../config/settings';

@Injectable({ providedIn: 'root' })
export class SentryErrorHandler extends ErrorHandler {
  private readonly appConfig = inject(AppConfig);
  /**
   * Environments in which the user feedback form should
   * be shown.
   */
  private readonly envWithUserFeedback = ['staging'];
  /**
   * `true` if sentry should be enabled for this environment.
   */
  private readonly enabled = this.appConfig.env.production;
  /** Logs errors to console if true. */
  private readonly logErrors = !this.enabled;

  /** instance of a sentry error handler. */
  private _errorHandler?: Sentry.SentryErrorHandler;
  private readonly breadcrumbLog = logger({ name: 'Sentry' });

  constructor() {
    super();

    this.appConfig.settings$.pipe(first()).subscribe((settings) => {
      this.initSentry({
        enabled: this.enabled,
        dsn: settings.sentryDsn,
        environment: settings.sentryEnvironment,
        release: settings.sentryRelease,
        tracesSampleRate: this._getTraceSampleRate(settings),
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: this._getReplayOnErrorSampleRate(settings),
        integrations: [
          Sentry.browserTracingIntegration({
            enableInp: true,
            beforeStartSpan: (context) =>
              this._transformTransactionPaths(context),
          }),
          Sentry.breadcrumbsIntegration({
            dom: {
              serializeAttribute: ['data-testid', 'id', 'class'],
            },
          }),
          Sentry.replayIntegration(),
        ],
      });
    });
  }

  override handleError(error: unknown): void {
    // When the error handler is already available, use it to handle the error
    if (this._errorHandler) {
      this._errorHandler.handleError(error);
      return;
    }

    // Otherwise fallback to capturing the exception manually
    Sentry.captureException(_extractError(_findOriginalError(error) ?? error));
    if (this.logErrors) {
      super.handleError(error);
    }
  }

  /**
   * Initialilzes sentry with the given settings.
   *
   * @param settings
   */
  private initSentry(opts: Sentry.BrowserOptions) {
    const shouldShowUserFeedbackForm = this.envWithUserFeedback.includes(
      opts.environment ?? '',
    );

    Sentry.init({
      ...opts,
      beforeBreadcrumb: (breadcrumb, hint) => {
        this._logBreadcrumb(breadcrumb);
        return breadcrumb;
      },
      beforeSend: (ev) => {
        if (shouldShowUserFeedbackForm) {
          // Check if it is an exception, and if so, show the report dialog
          if (ev.exception && ev.event_id) {
            Sentry.showReportDialog({ eventId: ev.event_id });
          }
        }

        return ev;
      },
      ignoreErrors: [
        // Errors caused by `WKWebView`
        // see https://github.com/getsentry/sentry-javascript/issues/3040#issuecomment-913549441
        `document.getElementsByTagName('video')[0].webkitExitFullScreen`,
        `evaluating 'window.webkit.messageHandlers`,
      ],
    });

    // Once sentry is all set up, create the underlying sentry error handler
    this._errorHandler = Sentry.createErrorHandler({
      // Use `beforeSend` event to handle report dialog
      showDialog: false,
      // Use Angulars error handler for logging errors
      logErrors: this.logErrors,
      extractor: (error, defaultExtractor) => {
        // Sentrys default extractor tries to unwrap zone.js errors.
        // It might return `null`, in which case we use the original.
        const unwrapped = defaultExtractor(error) ?? error;
        // Now with a potentially unwrapped error, we will run our extractor
        // and Sentrys extractor again, to handle `HttpErrorResponse`s.
        // Again, if that returns a `null`, use the original.
        return defaultExtractor(_extractError(unwrapped)) ?? error;
      },
    });
  }

  /**
   * Renames the transaction paths, in order to keep them grouped
   * properly on Sentry.
   *
   * @param context
   *
   * @returns modified context
   */
  private _transformTransactionPaths(
    context: TransactionContext,
  ): TransactionContext {
    // Change all digit/chars of lenght 10 to `:hash` (used for verify-urls)
    // Change all digits to `:id`
    let name = location.pathname
      .replace(/\/[a-f0-9]{10}/g, '/:hash')
      .replace(/\/\d+/g, '/:id');

    // Transaction paths can differ by an additional '/' character at the end
    // This will append the '/' when it's not present
    name += name.endsWith('/') ? '' : '/';

    return {
      ...context,
      name,
    };
  }

  /**
   * @param settings Settings on which the sample rate is based.
   * @returns
   * A percent value of how much transactions should be sampled.
   * The value will depend on the environment.
   */
  private _getTraceSampleRate(settings: Settings): number {
    switch (settings.sentryEnvironment) {
      case 'staging':
        // Capture 100% of transactions on staging for
        // performance monitoring
        return 1.0;
      case 'production':
        // Only trace 10% on production as there are a lot more clients
        return 0.1;
      default:
        // All other environments shouldn't trace transactions
        return 0;
    }
  }

  /**
   * @param settings Settings on which the session replay rate is based.
   * @returns
   * A percent value of how many Session Replays should be captured for Errors.
   * The value will depend on the environment.
   */
  private _getReplayOnErrorSampleRate(settings: Settings): number {
    switch (settings.sentryEnvironment) {
      case 'staging':
        // Capture 100% of replays on staging for errors
        return 1.0;
      case 'production':
        // Capture only 10% on production as there are a lot more clients
        return 0;
      default:
        // All other environments shouldn't capture replays
        return 0;
    }
  }

  /**
   * Logs the `breadcrumb` if all predicates are fulfilled.
   *
   * @param breadcrumb Breadcrumb to log.
   */
  private _logBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
    if (_shouldLogBreadcrumb(breadcrumb)) {
      this.breadcrumbLog.log(logMessageFromSentryBreadcrumb(breadcrumb));
    }
  }
}

const SKIP_LOG_BY_TYPE_REGEX = /^(default|debug)/;
const SKIP_LOG_BY_CATEGORY_REGEX = /^(console|navigation|ui|sentry|ngrx)/;
function _shouldLogBreadcrumb(breadcrumb: Sentry.Breadcrumb): boolean {
  // See https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
  const { category, type } = breadcrumb;

  if (
    (type && SKIP_LOG_BY_TYPE_REGEX.test(type)) ||
    (category && SKIP_LOG_BY_CATEGORY_REGEX.test(category))
  ) {
    return false;
  } else if (type === 'http' || category === 'xhr') {
    // Not logging http requests for now
    return false;
  }

  return true;
}

/**
 * Extracts the relevant information from known errors.
 *
 * @param error Some error that was thrown
 * @returns
 */
function _extractError(error: unknown): unknown {
  if (error instanceof GeneralHttpError) {
    return _extractFromGeneralHttpError(error);
  }

  return error;
}

/**
 * Extracts inner error from `GeneralHttpError`.
 *
 * @returns
 * Inner error
 */
function _extractFromGeneralHttpError({
  error,
  httpError,
}: GeneralHttpError): Error {
  // Special handling in case that we're dealing with an error from the
  // network layer and not directly from the API.
  if (error instanceof HttpClientOrNetworkError) {
    return httpError;
  }
  return error;
}

// -------------------------------------------------------------------------------
// Angular Error Handling:
// The following section are helpers to extract the original errors from
// Angular. For more information check the Angular implementation
// https://github.com/angular/angular/blob/main/packages/core/src/error_handler.ts
// https://github.com/angular/angular/blob/main/packages/core/src/util/errors.ts
// -------------------------------------------------------------------------------
const ERROR_ORIGINAL_ERROR = 'ngOriginalError';

function _getOriginalError(error: Error): Error {
  return (error as any)[ERROR_ORIGINAL_ERROR];
}

function _findOriginalError(error: any): Error | null {
  let e = error && _getOriginalError(error);
  while (e && _getOriginalError(e)) {
    e = _getOriginalError(e);
  }

  return e || null;
}
