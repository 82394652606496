import { Injectable } from '@angular/core';
import { FmntsApiError } from '@fmnts/api';
import {
  FmntsApiRequestError,
  UNEXPECTED_FMNTS_API_REQUEST_ERROR,
} from './errors.model';
import { GeneralHttpError } from './general-http-error';
import { HttpClientOrNetworkError } from './http-client-or-network-error';

/**
 * A common error handler for errors related to our API.
 * Does not handle `LegacyFmntsApiError`.
 */
@Injectable({
  providedIn: 'root',
})
export class FmntsApiErrorHandler {
  /**
   * @param error Unknown error to unwrap
   * @returns
   * Based on the given error:
   * - `FmntsApiError`: Server error response
   * - `HttpClientOrNetworkError`: When there was some connection problem
   * - `'unknown_error'`: Some other error
   */
  unwrap(error: unknown): FmntsApiRequestError {
    let _e = error;
    if (error instanceof GeneralHttpError) {
      _e = error.error;
    }

    if (_e instanceof FmntsApiError || _e instanceof HttpClientOrNetworkError) {
      return _e;
    }
    return UNEXPECTED_FMNTS_API_REQUEST_ERROR;
  }
}
