import { FmntsApiError } from '@fmnts/api';
import { HttpClientOrNetworkError } from './http-client-or-network-error';

export type ApiEndpointId = 'fmnts' | 'unknown';

/**
 * Represents an error that is unexpected to occur for requests
 * made to the formunauts API.
 */
export const UNEXPECTED_FMNTS_API_REQUEST_ERROR =
  'fmnts_api_request_unexpected_error';

export type FmntsApiRequestError =
  | FmntsApiError
  | HttpClientOrNetworkError
  | typeof UNEXPECTED_FMNTS_API_REQUEST_ERROR;
