import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonGroupComponent } from './button-group.component';
import { ButtonComponent } from './button.component';
import { IconButtonComponent } from './icon-button.component';

@NgModule({
  declarations: [ButtonComponent, ButtonGroupComponent, IconButtonComponent],
  exports: [ButtonComponent, ButtonGroupComponent, IconButtonComponent],
  imports: [CommonModule],
})
export class FmntsButtonModule {}
