import {
  EnvironmentProviders,
  Injectable,
  InjectionToken,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import { WINDOW } from '@fmnts/common/browser';
import {
  ConsoleLogDriver,
  LogEvent,
  LogEventPredicateFn,
  LogLevel,
  minLogLevelFilter,
  provideIndexedDbLogDriver,
  provideLogDriver,
  provideLogging,
  withEventFilters,
  withEventFiltersFromParent,
} from '@fmnts/common/log';
import { filterStoreLogEvents } from '@fmnts/common/store';
import {
  filterEventsFromSentryBreadcrumbs,
  provideSentryLogDriver,
} from '@fmnts/shared/sentry/infra';
import { environment } from '../../environments/environment';
import { LoggingEnvConfig } from '../../environments/environment.type';
import { AppLogsIdbRepository } from './diagnostic/data-access';

export function provideAppLogging(): EnvironmentProviders {
  return makeEnvironmentProviders([
    DynamicConsoleLogDriverFilter,
    provideLogging(withEventFilters(filterEventsFromSentryBreadcrumbs)),
    // Logs should be written to console
    provideLogDriver(
      ConsoleLogDriver,
      withEventFiltersFromParent(),
      withEventFilters(
        // Prefer using redux dev tools
        filterStoreLogEvents,
        (log) => inject(DynamicConsoleLogDriverFilter).filter(log),
      ),
    ),
    // Logs should be persisted in IndexedDB
    provideIndexedDbLogDriver(
      AppLogsIdbRepository,
      environment.log.idb.driver,
      withEventFilters(minLogLevelFilter(environment.log.idb.level)),
    ),
    // Logs should be send to sentry
    provideSentryLogDriver(
      {
        minimumBreadcrumbLevel: LogLevel.Debug,
        minimumEventLevel: LogLevel.Error,
      },
      withEventFiltersFromParent(),
    ),
  ]);
}

/**
 * Allows for dynamically configuring the log level for the console log driver.
 */
@Injectable()
class DynamicConsoleLogDriverFilter {
  private _filter!: LogEventPredicateFn;
  private readonly _win = inject(WINDOW);

  constructor() {
    const logEnv = inject(LOG_ENV_TOKEN);
    this.setLogLevel(logEnv.console.level);
  }

  filter(log: LogEvent): boolean {
    return this._debuggingActive() || this._filter(log);
  }

  /**
   * Sets `level` as the minimum logging level.
   *
   * @param level New minimum log level.
   */
  setLogLevel(level: LogLevel) {
    this._filter = minLogLevelFilter(level);
  }

  private _debuggingActive(): boolean {
    return (
      (this._win as unknown as Record<string, unknown>)['__FMNTS_DEBUG__'] ===
      true
    );
  }
}

/**
 * DI token to read the environment config for logging.
 */
const LOG_ENV_TOKEN = new InjectionToken<LoggingEnvConfig>(
  '@fmnts.app.env.log',
  {
    factory: () => environment.log,
  },
);
