/* eslint-disable @angular-eslint/component-selector */

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';
import { ButtonBaseComponent } from './button-base';

/**
 * Icon button component. This type of button displays a single interactive icon
 * for users to perform an action.
 */
@Component({
  selector: `button[fmnts-icon-button], a[fmnts-icon-button]`,
  template: `<ng-content></ng-content>`,
  styleUrls: ['button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent extends ButtonBaseComponent {
  @HostBinding('class.fmnts-icon-button') protected readonly iconButtonClass =
    'fmnts-icon-button';
}
