import { DOCUMENT } from '@angular/common';
import { ApplicationRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  SwUpdate,
  VersionEvent,
  VersionReadyEvent,
} from '@angular/service-worker';
import { logger } from '@fmnts/common/log';
import {
  catchError,
  concat,
  exhaustMap,
  filter,
  first,
  from,
  interval,
  of,
} from 'rxjs';

/**
 * Service for updating app bundle.
 */
@Injectable({ providedIn: 'root' })
export class UpdateService {
  private readonly appRef = inject(ApplicationRef);
  private readonly swUpdate = inject(SwUpdate);
  private readonly doc = inject(DOCUMENT);
  private readonly _updateLocks = new Set<unknown>();
  private readonly log = logger({ name: 'UpdateService' });

  private get safeToUpdate(): boolean {
    return this._updateLocks.size === 0;
  }

  /**
   * Emits whenever a new version has been downloaded and is ready for activation.
   */
  private readonly versionReady$ = this.swUpdate.versionUpdates.pipe(
    filter(isVersionReadyEvent),
  );

  constructor() {
    // Only run, if service worker is enabled
    if (!this.swUpdate.isEnabled) {
      this.log.debug('Service worker not enabled');
      return;
    }

    this.shouldCheckForUpdate()
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.checkForUpdate();
      });

    this.versionReady$
      .pipe(
        // Check if activating the update is safe, because updating without
        // reloading can break lazy-loading of modules
        filter(() => this.safeToUpdate),
        exhaustMap(() =>
          this.updateVersion().pipe(
            catchError(() => of(null)),
            filter((updated) => updated === true),
          ),
        ),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.log.info('new version activated');
        this.doc.location.reload();
      });
  }

  // Add item to update locks, preventing updates from happening.
  public lockUpdates(lock: unknown): void {
    this._updateLocks.add(lock);
  }

  // Remove item from update locks, allowing updates to happen, if other locks
  // don't prevent it.
  public unlockUpdates(lock: unknown): void {
    this._updateLocks.delete(lock);
  }

  private checkForUpdate() {
    this.log.info('check for update');
    void this.swUpdate.checkForUpdate();
  }

  /**
   * Performs a version update and emits with
   * - `true` if the update was successful
   * - `false` if there was no update available
   */
  private updateVersion() {
    return from(this.swUpdate.activateUpdate());
  }

  private shouldCheckForUpdate() {
    // Wait for the app to stableize, before starting checking for updates
    const appIsStable$ = this.appRef.isStable.pipe(
      first((isStable) => isStable === true),
    );
    const everyHour$ = interval(60 * 60 * 1000);
    return concat(appIsStable$, everyHour$);
  }
}

function isVersionReadyEvent(evt: VersionEvent): evt is VersionReadyEvent {
  return evt.type === 'VERSION_READY';
}
