/* eslint-disable @typescript-eslint/naming-convention */
import { ApiTokenResponse, AuthInfo, AuthSession } from '@fmnts/api/auth';
import { createHttpActions } from '@fmnts/common/store';
import { GeneralHttpError } from '@fmnts/shared/errors/data-access';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

enum AuthActionSource {
  Api = 'Auth Api',
  Interceptor = 'Auth Interceptor',
  LoginPage = 'Login Page',
  Root = 'Auth Root',
}

export const AuthRootActions = createActionGroup({
  source: AuthActionSource.Root,
  events: {
    Hydrate: props<{ authInfo: AuthInfo; session: AuthSession }>(),
    'Forget User': emptyProps(),
  },
});

export const AuthInterceptorActions = createActionGroup({
  source: AuthActionSource.Interceptor,
  events: {
    'Unauthorized Requested': emptyProps(),
  },
});

export const AuthLoginPageActions = createActionGroup({
  source: AuthActionSource.LoginPage,
  events: {
    Login: props<{ username: string; password: string }>(),
  },
});

const AuthApiRefreshActions = createHttpActions(
  AuthActionSource.Api,
  'Refresh',
  {
    success: props<{ payload: ApiTokenResponse }>(),
    failure: props<{ error: GeneralHttpError }>(),
  },
);

const AuthApiAuthInfoActions = createHttpActions(
  AuthActionSource.Api,
  'Auth Info',
  {
    success: props<{ payload: AuthInfo }>(),
    failure: props<{ error: GeneralHttpError }>(),
  },
);

const AuthApiAuthenticateActions = createHttpActions(
  AuthActionSource.Api,
  'Authenticate',
  {
    request: props<{ username: string; password: string }>(),
    success: props<{ payload: ApiTokenResponse }>(),
    failure: props<{ error: GeneralHttpError }>(),
  },
);

const AuthApiUnauthenticateActions = createHttpActions(
  AuthActionSource.Api,
  'Unauthenticate',
  {
    failure: props<{ error: GeneralHttpError }>(),
  },
);

export const AuthApiActions = {
  authInfo: AuthApiAuthInfoActions,
  refresh: AuthApiRefreshActions,
  authenticate: AuthApiAuthenticateActions,
  unauthenticate: AuthApiUnauthenticateActions,
};
