import type { Adapter } from '@fmnts/core';

import { CssInlineStyles } from '../model/css-inline-styles';

export class CssInlineStylesAdapter implements Adapter<CssInlineStyles> {
  adapt(item: Record<string, unknown>): CssInlineStyles {
    if (!item || typeof item !== 'object') {
      return {};
    }

    const inlineStyles: CssInlineStyles = {};

    // background color
    if ('background_color' in item) {
      inlineStyles['background-color'] = item.background_color as string;
    }

    return inlineStyles;
  }
}
