/* eslint-disable @angular-eslint/component-selector */

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { ButtonBaseComponent, ButtonShape } from './button-base';

/**
 * Displays a button.
 */
@Component({
  selector: `button[fmnts-button], a[fmnts-button]`,
  templateUrl: 'button.component.html',
  styleUrls: ['button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent extends ButtonBaseComponent {
  /**
   * Set to `true` if the button should fill the width of the container
   */
  @Input() get fillContainer(): boolean {
    return this._fillContainer;
  }
  set fillContainer(value: BooleanInput) {
    this._fillContainer = coerceBooleanProperty(value);
  }
  private _fillContainer = false;

  /**
   * Controls the shape of the button
   */
  @Input() shape: ButtonShape = 'rounded';

  @HostBinding('class')
  override get hostClasses(): string {
    return classnames([
      super.hostClasses,
      // Shape variants
      `${this.componentClass}--${this.shape}`,
      // Variants that control behavior within the container
      this.fillContainer && `${this.componentClass}--fill-container`,
    ]);
  }
}
