import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { Bundle, BundleInfo } from '@formunauts/shared/bundle/application';

export function provideBundleDataAccess(
  opts: BundleInfo,
): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: Bundle,
      useFactory: () => new Bundle(opts),
    },
  ]);
}
